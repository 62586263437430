import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import DataContext from '../../../data/context'
import { find } from 'lodash'
import { formatFirstRegistration, formatNumber } from '../../../utils'

export const VehicleSummaryIndicata = ({ onClose }) => {
    const { inspection, indicataState, data, fetchNextState, indicataNavSteps } = useContext(DataContext)
    // console.log('VehicleSummaryIndicata indicataState: ', indicataState)
    // console.log('VehicleSummaryIndicata indicataNavSteps: ', indicataNavSteps)
    // console.log('VehicleSummaryIndicata inspection: ', inspection)
    // console.log('VehicleSummaryIndicata data: ', data)
    const { t } = useTranslation()

    const handleSummaryItemClick = (link, step) => {
        if (link) {
            fetchNextState(link)
        }
    }

    const findValue = (collection, value, resultField) => {
        const tmp = find(collection, (i) => i.key === value)
        if (tmp && tmp[resultField]) {
            return tmp[resultField]
        } else {
            return formatValue(undefined)
        }
    }

    const formatValue = (value) => {
        return value ? value : ''
    }

    const categoryValue = inspection?.category?.name
    const makeValue = inspection?.make
    const modelValue = inspection?.model
    const registrationDate = formatFirstRegistration(
        inspection.firstRegistrationYear,
        inspection.firstRegistrationMonth,
    )
    const bodyTypeValue = inspection?.body ?? ''
    const faceliftValue = inspection?.facelift?.name ?? ''
    const seatsValue = inspection?.seats ?? ''
    const heightValue = inspection?.bodyHeight?.summary ?? ''
    const lengthValue = inspection?.bodyLength?.summary ?? ''
    const weightValue = inspection?.bodyWeight?.summary ?? ''
    const engineValue = inspection?.engine ?? ''
    const wheelDriveValue = inspection?.wheelDrive ?? ''
    const transmissionValue = inspection?.transmission ?? ''
    const trimValue = inspection?.trim?.name ?? ''
    const mileageValue = inspection.mileage ? `${formatNumber(inspection.mileage)} km` : ''
    const vinValue = formatValue(inspection.vin) ?? ''

    const categoryItems = [
        {
            name: 'Categorie',
            step: 0,
            rel: 'category',
            value: categoryValue,
            link: indicataState?.category?.link?.href,
        },
        { name: t('Make'), step: 1, rel: 'make', value: makeValue, link: indicataState?.make?.link?.href },
        { name: t('Model'), step: 2, rel: 'model', value: modelValue, link: indicataState?.model?.link?.href },
        {
            name: t('First registration'),
            step: 3,
            rel: 'regmonth',
            value: registrationDate,
            link: indicataState?.regDate?.link?.href,
        },
        { name: t('Bodytype'), step: 5, rel: 'body', value: bodyTypeValue, link: indicataState?.body?.link?.href },
        {
            name: t('Facelift'),
            step: 6,
            rel: 'facelift',
            value: faceliftValue,
            link: indicataState?.facelift?.link?.href,
        },
        { name: t('Seats'), step: 7, rel: 'seats', value: seatsValue, link: indicataState?.seats?.link?.href },
        { name: t('Height'), step: 8, value: heightValue, rel: 'height', link: indicataState?.bodyHeight?.link?.href },
        { name: t('Length'), step: 9, value: lengthValue, rel: 'length', link: indicataState?.bodyLength?.link?.href },
        { name: t('Weight'), step: 10, value: weightValue, rel: 'weight', link: indicataState?.weight?.link?.href },
        { name: t('Engine'), step: 11, rel: 'engine', value: engineValue, link: indicataState?.engine?.link?.href },
        {
            name: t('Wheel drive'),
            step: 12,
            rel: 'wheeldrive',
            value: wheelDriveValue,
            link: indicataState?.wheelDrive?.link?.href,
        },
        {
            name: t('Transmission'),
            step: 13,
            rel: 'transmission',
            value: transmissionValue,
            link: indicataState?.transmission?.link?.href,
        },
        { name: t('Trim'), step: 14, rel: 'trim', value: trimValue, link: indicataState?.trim?.link?.href },
        { name: t('Mileage'), step: 15, rel: '', value: mileageValue, link: '' },
        { name: t('Chassisnumber'), step: 16, rel: '', value: vinValue, link: '' },
    ]

    const items = categoryItems.map((item) => {
        return (
            <div
                key={item.step}
                className={`vehicle-summary-item flex justify-between border-b pb-2 mb-6 space-x-2 ${
                    onClose ? 'border-color_one' : 'border-white'
                } ${!item.value ? 'text-color_three' : ''}`}
                // Enable if navigation via summary is required
                // onClick={() => handleSummaryItemClick(item.link, item.step)}
            >
                <div>{item.name}</div>
                <div className="font-bold truncate text-right">{item.value}</div>
            </div>
        )
    })

    return (
        <div className="mt-1 h-full" style={{ width: 350 }}>
            {onClose && (
                <div
                    className="flex items-center justify-between text-color_one pl-8 pr-8 font-bold "
                    style={{ height: 50 }}
                >
                    <div>
                        <i className="fas fa-info-circle text-14 mr-2" />
                        {t('Vehicle specifications')}
                    </div>
                    <div>
                        <i className="fal fa-times text-18 cursor-pointer font-bold" onClick={onClose} />
                    </div>
                </div>
            )}

            <div className="flex flex-col w-full h-full p-8 truncate">{items}</div>
        </div>
    )
}
