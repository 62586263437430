import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys } from 'lodash'
import { TextField } from '../../input'
import { hasValue, isValidEmail } from '../../../validators'
import { Facebook, Google, LinkedIn, Microsoft } from '../../social'
import { getSocialAuthUrlForProvider } from '../../social/utils'
import { inspectionToStorage } from '../../../data/localstorage'
import { useHistory, useLocation } from 'react-router-dom'
import SelectBox from '../../input/SelectBox'
import ContentPopup from '../../legal/ContentPopup'
import WizardButtons from '../WizardButtons'
import DealerLocator from './DealerLocator'
import { getUrlParams } from '../../../utils'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

const formTypes = {
    simple: 'simple',
    advanced: 'advanced',
}

const PHONE_NUMBER_REGEX = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{6,}$/

const Identification = () => {
    const { inspection, setField, dealerKey, dealerConfig, isAuthenticated, session, vehicleWizardStep, signOut } =
        useContext(DataContext)
    const [privacyAccepted, setPrivacyAccepted] = useState(false)
    const [errors, setErrors] = useState({})

    const [privacyModalVisible, setPrivacyModalVisible] = useState(false)
    const { t } = useTranslation()
    const location = useLocation()
    let history = useHistory()
    const [formType] = useState(dealerConfig.advancedIdentificationForm ? formTypes.advanced : formTypes.simple)
    const [dealerLocatorVisible, setDealerLocatorVisible] = useState(false)
    const dealerLocatorRequired = dealerConfig.dealerLocator
    const [showNameFields, setShowNameFields] = useState(
        !hasValue(inspection.firstName) || !hasValue(inspection.lastName),
    )
    const lma = getUrlParams(window.location.search)['lma']
    const stepInfo = getToolStepInfo(dealerConfig.provider, 'personal-info')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        if (isAuthenticated) {
            setUserData(session.user)
        }
    }, [])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}
        let aTrack = analyticstracker()

        const trackError = (error) => {
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: error,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        if (!hasValue(inspection.email)) {
            errors['email'] = t('Email address required')
            trackError(errors.email)
        } else if (!isValidEmail(inspection.email)) {
            errors['email'] = t('Invalid email address')
            trackError(errors.email)
        }

        if (dealerConfig.formsPrivacyTickBox && !privacyAccepted) {
            errors['privacy'] = t('Please accept the privacy conditions')
            trackError(errors.privacy)
        }

        if (formType === formTypes.advanced) {
            if (!hasValue(inspection.firstName)) {
                errors['firstName'] = t('First name required')
                trackError(errors.firstName)
            }

            if (!hasValue(inspection.lastName)) {
                errors['lastName'] = t('Last name required')
                trackError(errors.lastName)
            }

            if (!hasValue(inspection.telephone)) {
                errors['telephone'] = t('Telephone number required')
                trackError(errors.telephone)
            }

            if (dealerConfig.advancedIdentificationFormAddressRequired) {
                if (!hasValue(inspection.street)) {
                    errors['street'] = t('Street required')
                    trackError(errors.street)
                }

                if (!hasValue(inspection.number)) {
                    errors['number'] = t('Number is required')
                    trackError(errors.number)
                }

                if (!hasValue(inspection.postalCode)) {
                    errors['postalCode'] = t('Postalcode required')
                    trackError(errors.postalCode)
                }

                if (!hasValue(inspection.city)) {
                    errors['city'] = t('City required')
                    trackError(errors.city)
                }
            }

            if (hasValue(inspection.telephone) && !inspection.telephone.match(PHONE_NUMBER_REGEX)) {
                errors['telephone'] = t('Telephone number invalid')
                trackError(errors.telephone)
            }
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const setUserData = (user) => {
        setField('firstName', user.firstName)
        setField('lastName', user.lastName)
        setField('email', user.email)
        setField('userId', user.id)

        setShowNameFields(!hasValue(user.firstName) || !hasValue(user.lastName))
    }

    const handleSocialAuth = (provider) => {
        const key = inspectionToStorage({
            inspection: inspection,
            step: vehicleWizardStep,
        })

        history.replace({
            ...location,
            search: `key=${key}`,
        })

        window.location.href = getSocialAuthUrlForProvider(
            provider,
            dealerKey,
            `${window.location.origin}/${dealerKey}/inspection?key=${key}`,
        )
    }

    const handleSignOut = () => {
        setField('firstName', '')
        setField('lastName', '')
        setField('email', '')
        setField('userId', undefined)
        signOut()

        setShowNameFields(true)
    }

    const renderAdvancedForm = (title) => {
        return (
            <div>
                {title && <div className="text-color_one text-24 font-bold mb-4 mt-5 text-center">{title}</div>}

                <React.Fragment>
                    {showNameFields && (
                        <div className="mb-4">
                            <TextField
                                label={t('First name')}
                                placeholder=""
                                value={inspection.firstName}
                                onChange={(value) => setField('firstName', value)}
                                error={errors['firstName']}
                                required={true}
                            />
                        </div>
                    )}

                    {showNameFields && (
                        <div className="mb-4">
                            <TextField
                                label={t('Name')}
                                placeholder=""
                                value={inspection.lastName}
                                onChange={(value) => setField('lastName', value)}
                                error={errors['lastName']}
                                required={true}
                            />
                        </div>
                    )}
                </React.Fragment>

                <div className="mb-4">
                    <TextField
                        label={t('Street')}
                        placeholder=""
                        value={inspection.street}
                        onChange={(value) => setField('street', value)}
                        error={errors['street']}
                        required={dealerConfig.advancedIdentificationFormAddressRequired}
                    />
                </div>
                <div className="flex flex-row mb-4">
                    <div className="w-1/2 mr-2">
                        <TextField
                            label={t('Number')}
                            placeholder=""
                            value={inspection.number}
                            onChange={(value) => setField('number', value)}
                            error={errors['number']}
                            required={dealerConfig.advancedIdentificationFormAddressRequired}
                        />
                    </div>
                    <div className="w-16">
                        <TextField
                            label={t('Box')}
                            placeholder=""
                            value={inspection.bus}
                            onChange={(value) => setField('bus', value)}
                            error={errors['bus']}
                        />
                    </div>
                </div>

                <div className="w-1/2 mb-4">
                    <TextField
                        label={t('Postal')}
                        placeholder=""
                        value={inspection.postalCode}
                        onChange={(value) => setField('postalCode', value)}
                        error={errors['postalCode']}
                        required={dealerConfig.advancedIdentificationFormAddressRequired}
                    />
                </div>

                <div className="mb-4">
                    <TextField
                        label={t('City')}
                        placeholder=""
                        value={inspection.city}
                        onChange={(value) => setField('city', value)}
                        error={errors['city']}
                        required={dealerConfig.advancedIdentificationFormAddressRequired}
                    />
                </div>

                {!inspection.userId && (
                    <div className="mb-4">
                        <TextField
                            label={t('E-mail address')}
                            placeholder=""
                            value={inspection.email}
                            onChange={(value) => setField('email', value)}
                            error={errors['email']}
                            readOnly={inspection.userId}
                            required={true}
                            type="email"
                        />
                    </div>
                )}
                <div className="mb-6">
                    <TextField
                        label={t('Telephone number')}
                        placeholder=""
                        value={inspection.telephone}
                        onChange={(value) => setField('telephone', value)}
                        error={errors['telephone']}
                        required={true}
                        type="tel"
                    />
                </div>
                {/*<div className="flex flex-row mb-2">*/}
                {/*    <div className="mr-5">*/}
                {/*        <SelectBox*/}
                {/*            description=""*/}
                {/*            selected={inspection.newsletter}*/}
                {/*            onChange={() => setField("newsletter", !inspection.newsletter)}*/}
                {/*            error={false}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*    <p*/}
                {/*        className="text-color_three text-14 items-center"*/}
                {/*        dangerouslySetInnerHTML={{*/}
                {/*            __html: t(*/}
                {/*                "Yes, I want to get regular updates about {{styling}}products{{stylingEnd}}, {{styling}}services{{stylingEnd}} and {{styling}}promos{{stylingEnd}}, useful advice or {{styling}}invitations{{stylingEnd}} to events",*/}
                {/*                {*/}
                {/*                    styling: "<strong>",*/}
                {/*                    stylingEnd: "</strong />",*/}
                {/*                    interpolation: {escapeValue: false},*/}
                {/*                }*/}
                {/*            ),*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        )
    }

    return (
        <React.Fragment>
            {!dealerLocatorVisible && (
                <div
                    data-tracking-event="tool-start"
                    data-tracking-info={tagArgsStart}
                    data-tracking-commerce={tagArgsCommerce}
                >
                    <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Identification')}</div>

                    {!inspection.userId && (
                        <React.Fragment>
                            {!dealerConfig.socialAuthentication && (
                                <p className="text-center text-16 mt-6 mb-6">
                                    {t(
                                        'Your privacy is important to us. We need some information to send you the car valuation.',
                                    )}
                                </p>
                            )}

                            {dealerConfig.socialAuthentication && (
                                <p className="text-center text-16 mt-6 mb-6">
                                    {t(
                                        'Your privacy is important to us. We need some information to send you the car valuation. Choose one of the social media buttons below to fill out the gaps.',
                                    )}
                                </p>
                            )}

                            {dealerConfig.socialAuthentication && (
                                <div className="flex flex-1 justify-center  ">
                                    <div className="mr-2">
                                        <Google onClick={() => handleSocialAuth('google-oauth2')} />
                                    </div>
                                    <div className="mr-2">
                                        <Facebook onClick={() => handleSocialAuth('facebook')} />
                                    </div>
                                    <div className="mr-2">
                                        <LinkedIn onClick={() => handleSocialAuth('linkedin-oauth2')} />
                                    </div>
                                    <div className="">
                                        <Microsoft onClick={() => handleSocialAuth('microsoft-graph')} />
                                    </div>
                                </div>
                            )}

                            {dealerConfig.socialAuthentication && (
                                <div
                                    className="w-full border-b border-color_five text-center mb-5 mt-5"
                                    style={{ height: 10 }}
                                >
                                    <span className="text-10 pl-4 pr-4 bg-white" style={{ color: '#B9BED1' }}>
                                        {t('or')}
                                    </span>
                                </div>
                            )}

                            {formType === formTypes.simple && (
                                <div className="mb-5">
                                    <TextField
                                        label={t('Your e-mail address')}
                                        placeholder="john.doe@example.com"
                                        value={inspection.email}
                                        onChange={(value) => setField('email', value)}
                                        error={errors['email']}
                                        type="email"
                                    />
                                </div>
                            )}

                            {formType === formTypes.advanced && renderAdvancedForm()}
                        </React.Fragment>
                    )}

                    {inspection.userId && (
                        <React.Fragment>
                            <p className="text-center text-16 mt-6 mb-6">
                                {t(
                                    "We have received your name and email address. Click below on 'I agree with the privacy conditions' and start the valuation.",
                                )}
                            </p>

                            <React.Fragment>
                                <div className="mb-8">
                                    {((inspection.firstName !== '' && !showNameFields) ||
                                        (inspection.lastName !== '' && !showNameFields)) && (
                                        <div className="mb-4 text-center">
                                            <label
                                                className={`block uppercase tracking-wide text-14 font-bold mb-1 text-color_one`}
                                            >
                                                {t('Name')}
                                            </label>
                                            <div className="text-color_three text-center">
                                                {inspection.firstName !== '' || inspection.lastName !== ''
                                                    ? `${inspection.firstName} ${inspection.lastName}`
                                                    : '/'}
                                            </div>
                                        </div>
                                    )}

                                    <div className="text-center">
                                        <label
                                            className={`block uppercase tracking-wide text-14 font-bold mb-1 text-color_one`}
                                        >
                                            {t('E-mail address')}
                                        </label>
                                        <div className="text-color_three text-center">{inspection.email}</div>
                                    </div>
                                </div>
                            </React.Fragment>

                            <div className="flex justify-center mb-6">
                                <div className="mr-2">{t('Wrong personal data?')}</div>
                                <div className="underline cursor-pointer" onClick={handleSignOut}>
                                    {t('Use other account.')}
                                </div>
                            </div>

                            {formType === formTypes.advanced && renderAdvancedForm(t('Additional information'))}
                        </React.Fragment>
                    )}

                    {/* <hr className="solid mb-5 border-color_five md:hidden"/> */}

                    {dealerConfig.formsPrivacyTickBox && (
                        <div className="flex mb-10 items-center">
                            <SelectBox
                                description=""
                                selected={privacyAccepted}
                                onChange={(value) => setPrivacyAccepted(value)}
                                error={errors['privacy']}
                            />
                            <div
                                className={`cursor-pointer ${
                                    errors['privacy'] ? 'text-color_error' : 'text-color_three'
                                } text-14  ml-5`}
                                dangerouslySetInnerHTML={{
                                    __html: t('I agree to the {{styling}} privacy terms {{stylingEnd}}', {
                                        styling: '<span class="underline cursor-pointer">',
                                        stylingEnd: '</span />',
                                        interpolation: { escapeValue: false },
                                    }),
                                }}
                                onClick={() => setPrivacyModalVisible(true)}
                            ></div>
                        </div>
                    )}

                    {errors && keys(errors).length > 0 && (
                        <div className="flex items-center justify-center mb-2">
                            <div className="text-color_error text-12">
                                {' '}
                                {t('Complete the missing fields to continue')}
                            </div>
                        </div>
                    )}

                    {privacyModalVisible && (
                        <ContentPopup type="privacy_policy" onClose={() => setPrivacyModalVisible(false)} />
                    )}

                    {dealerLocatorRequired && (
                        <WizardButtons
                            validatorFunction={validator}
                            onNext={() => setDealerLocatorVisible(true)}
                            trackingEvent="tool-submit"
                            trackingInfo={TAGMANAGER_TOOL_ARGS({
                                event: 'submit',
                                ...stepInfo,
                            })}
                            trackingCommerce={tagArgsCommerce}
                        />
                    )}

                    {!dealerLocatorRequired && <WizardButtons validatorFunction={validator} />}
                </div>
            )}

            {dealerLocatorVisible && <DealerLocator handlePrevious={() => setDealerLocatorVisible(false)} lma={lma} />}
        </React.Fragment>
    )
}
export default Identification
