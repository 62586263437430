/*
These function provide an easy way to generate the data that has to be send with the analytics packages
*/

export const TAGMANAGER_PAGE_ARGS = ({ section = '', language = '', pageName = '' }) => {
    const fullUrl = window.location.href
    const searchParams = new URLSearchParams(window.location.search)
    const lma = searchParams.get('lma')
    const pageviewMode = lma ? 'dealer' : 'national'

    const hostname = window.location.hostname

    let brand
    if (hostname.includes('audiapprovedplus')) {
        brand = 'audi'
    } else if (hostname.includes('myway')) {
        brand = 'myway'
    } else {
        brand = 'unknown'
    }

    return JSON.stringify({
        pageName: pageName || `car-quotation/${section}`,
        section: section,
        subSectionOne: '',
        subSectionTwo: '',
        pageUrl: fullUrl.split('?')[0],
        pageFullUrl: fullUrl,
        pageviewMode,
        pageType: 'homepage',
        platform: 'car-quotation',
        brand: brand, // audi or myway
        language: language,
        loginStatus: 'not logged in',
        previousPageName: '',
        previousPageUrl: '',
        previousPageFullUrl: '',
        previousPageType: '',
        previousPlatform: 'car-quotation',
        previousBrand: '',
        pageVersion: '',
        author: 'Autralis',
        dealerGroupID: lma || '',
        dealerGroupName: '',
        dealerID: '',
        dealerLocation: '',
        dealerName: '',
    })
}

export const TAGMANAGER_COMMERCE_ARGS = ({ carConfigCode = '' }) => {
    return JSON.stringify({
        // Following fields are required to send with event but not relevantin our case
        carBrand: '',
        carModel: '',
        referenceId: '',
        carConfigCode: carConfigCode,
        carEquipmentLine: '',
        carEngineType: '',
        carEnginePower: '',
        carGearboxType: '',
        carExteriorColor: '',
        carInteriorColor: '',
        carOptionsList: '',
        carPrice: '',
        carTotalPrice: '',
        carYear: '',
        carMileage: '',
    })
}

export const TAGMANAGER_TOOL_ARGS = ({
    toolStep = '',
    toolStepOption = '',
    toolStepNumber = '',
    inspectionPartTwo = false,
    inspectionPartThree = false,
    errorType = '',
    errorMessage = '',
}) => {
    const hostname = window.location.hostname
    let toolName
    if (hostname.includes('audiapprovedplus')) {
        toolName = 'aap-car-quotation'
    } else if (hostname.includes('myway')) {
        toolName = 'myway-car-quotation'
    } else {
        toolName = 'unknown'
    }

    //could use a rewrite, as they kept adding parts to the inspection
    if (inspectionPartTwo) {
        toolName = toolName + '-2'
    } else if (inspectionPartThree) {
        toolName = toolName + '-3'
    }

    return JSON.stringify({
        toolType: 'request-form',
        toolName: toolName,
        toolStep: toolStep,
        toolStepNumber: toolStepNumber,
        toolStepOption: toolStepOption,
        leadID: '',
        requestType: 'take-over',
        errorType: errorType || '',
        errorMessage: errorMessage || '',
    })
}

export const TAGMANAGER_BUTTON_ARGS = ({ buttonName = '', buttonText = '' }) => {
    return JSON.stringify({
        buttonType: 'button',
        buttonCategory: 'interaction',
        buttonName,
        buttonText,
        buttonTarget: '',
        buttonPosition: '',
    })
}

export const AUTRALIS_FLOW_STEPS = {
    brand: { toolStepNumber: '1' },
    registration: { toolStepNumber: '2' },
    model: { toolStepNumber: '3' },
    body: { toolStepNumber: '4' },
    fuel: { toolStepNumber: '5' },
    engine: { toolStepNumber: '6' },
    transmission: { toolStepNumber: '7' },
    mileage: { toolStepNumber: '8' },
    identification: { toolStepNumber: '9' },
    'new-car': { toolStepNumber: '10' },
    'personal-info': { toolStepNumber: '11' },
    'thank-you': { toolStepNumber: '12' },
}

export const INDICATA_FLOW_STEPS = {
    'car-category': { toolStepNumber: '1' },
    brand: { toolStepNumber: '2' },
    model: { toolStepNumber: '3' },
    registration: { toolStepNumber: '4' },
    body: { toolStepNumber: '5' },
    facelift: { toolStepNumber: '6' },
    seats: { toolStepNumber: '7' },
    height: { toolStepNumber: '8' },
    length: { toolStepNumber: '9' },
    weight: { toolStepNumber: '10' },
    engine: { toolStepNumber: '11' },
    wheelbase: { toolStepNumber: '12' },
    transmission: { toolStepNumber: '13' },
    equipment: { toolStepNumber: '14' },
    mileage: { toolStepNumber: '15' },
    identification: { toolStepNumber: '16' },
    'new-car': { toolStepNumber: '17' },
    'personal-info': { toolStepNumber: '18' },
    'thank-you': { toolStepNumber: '19' },
}

export const getToolStepInfo = (flowType = 'autralis', stepName) => {
    const flowSteps = {
        autralis: AUTRALIS_FLOW_STEPS,
        indicata: INDICATA_FLOW_STEPS,
    }
    return {
        toolStep: stepName,
        toolStepNumber: flowSteps[flowType]?.[stepName].toolStepNumber || '',
    }
}
