import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys, map, orderBy } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

export const Seats = () => {
    const { inspection, data, saveNavStep, fetchNextState } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo("indicata", 'seats')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.seats) {
            errors['seats'] = t('Please select a number of seats')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.seats,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleSeatsClick = (e, seat) => {
        let aTrack = analyticstracker()
        aTrack.trackInteraction(e)
        saveNavStep({ link: seat.link, rel: seat.rel })
        fetchNextState(seat.link)
    }

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Seats')}</div>

            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the number of seats in your vehicle')}:</div>

            <div className="flex flex-wrap mb-10">
                {
                    <SeatsCard
                        seats={data.seats}
                        inspection={inspection}
                        tagArgsCommerce={tagArgsCommerce}
                        handleOnClick={handleSeatsClick}
                    />
                }
            </div>

            <WizardError errors={errors} />

            <WizardButtons validatorFunction={validator} />
        </div>
    )
}

const SeatsCard = ({ seats, inspection, tagArgsCommerce, handleOnClick }) => {
    const stepInfo = getToolStepInfo("indicata", 'seats')
    return map(seats, (seat, i) => {
        const selected = seat.key === inspection.make
        const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: 'submit',
            ...stepInfo,
            toolStepOption: seat.name,
        })
        return (
            <div key={`make_${seat.name}`} className={`w-1/3 mb-2 ${i % 3 !== 2 ? 'pr-2' : ''}`}>
                <div
                    className={`flex items-center text-center justify-center bg-color_five border text-14 rounded-lg cursor-pointer ${
                        selected ? 'border-color_one text-color_one' : 'border-color_five text-color_three'
                    } hover:border-color_one hover:text-color_one capitalize`}
                    style={{ height: 70 }}
                    data-tracking-event="tool-submit"
                    data-tracking-info={tagArgsSubmit}
                    data-tracking-commerce={tagArgsCommerce}
                    onClick={(e) => handleOnClick(e, seat)}
                >
                    {seat.name}
                </div>
            </div>
        )
    })
}
