import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import NumericField from '../../input/NumericField'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

const Mileage = () => {
    const { inspection, setField, dealerConfig } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo(dealerConfig.provider, 'mileage')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.mileage) {
            errors['mileage'] = t('Please select the mileage of your car')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.mileage,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }
        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        const valid = validate()
        return valid
    }

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Mileage')}</div>

            <div className="text-16 leading-6 mb-4 mt-6">{t('How many kilometers does your vehicle have?')}</div>

            <div className="flex mb-10 justify-center">
                <div style={{ width: 250 }}>
                    <NumericField
                        value={inspection.mileage}
                        onChange={(value) => setField('mileage', value)}
                        suffix=" km"
                        placeholder="km"
                        error={errors['mileage']}
                    />
                </div>
                {/*<div className="ml-2 mt-5 flex items-center">km</div>*/}
            </div>

            <WizardError errors={errors} />

            <WizardButtons
                validatorFunction={validator}
                trackingEvent="tool-submit"
                trackingInfo={TAGMANAGER_TOOL_ARGS({
                    event: 'submit',
                    ...stepInfo,
                    toolStepOption: inspection.mileage,
                })}
            />
        </div>
    )
}

export default Mileage
