import React, { useContext, useEffect, useState } from 'react'
import { getWizardStepsByDealerConfig, getIndicataWizardStepsByDealerConfig } from './vehicleWizardConfig'
import DataContext from '../../data/context'
import StepIndicator from './StepIndicator'
import { useHeight } from '../../hooks/useHeight'
import { StickyElements } from '../../data/constants'
import VehicleSummary from './VehicleSummary'
import { VehicleSummaryIndicata } from './indicata/VehicleSummary'

const WIZARD_PROVIDER_CONFIG = {
    indicata: {
        getWizardSteps: getIndicataWizardStepsByDealerConfig,
        SummaryComponent: (props) => <VehicleSummaryIndicata {...props} />,
    },
    autralis: {
        getWizardSteps: getWizardStepsByDealerConfig,
        SummaryComponent: (props) => <VehicleSummary onClose={() => {}} {...props} />,
    },
}

export const INDICATA_STEP_MAPPINGS = {
    category: { step: 0 },
    make: { step: 1 },
    model: { step: 2 },
    regmonth: { step: 3 },
    regdate: { step: 4 },
    body: { step: 5 },
    facelift: { step: 6 },
    seats: { step: 7 },
    bodyHeight: { step: 8 },
    bodyLength: { step: 9 },
    weight: { step: 10 },
    engine: { step: 11 },
    wheeldrive: { step: 12 },
    transmission: { step: 13 },
    trim: { step: 14 },
}

const VehicleWizard = () => {
    const { vehicleWizardStep, dealerConfig, inspection, indicataState, goToVehicleDataStep } = useContext(DataContext)
    const [summaryVisible, setSummaryVisible] = useState(false)
    const topSpace = useHeight([StickyElements.header])

    const providerConfig = WIZARD_PROVIDER_CONFIG[dealerConfig.provider] || WIZARD_PROVIDER_CONFIG.autralis

    const wizardSteps = providerConfig.getWizardSteps(dealerConfig)
    const SummaryComponent = providerConfig.SummaryComponent

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [vehicleWizardStep])

    useEffect(() => {
        const isValuationReady =
            indicataState && indicataState.nextStep?.length === 0 && indicataState.valuation?.length > 0

        if (dealerConfig.provider === 'indicata' && indicataState && indicataState.nextStep?.length > 0) {
            const rel = indicataState.nextStep[0].rel
            const foundItem = INDICATA_STEP_MAPPINGS[rel]
            if (foundItem) {
                // console.log('%cgo to step: %s %s', 'color:cyan', rel, foundItem.step)
                goToVehicleDataStep(foundItem.step)
            }
        } else if (isValuationReady) {
            // console.log('%cvaluation ready go to step 15 (mileage) %s %s', 'color:green')
            goToVehicleDataStep(15)
        }
    }, [indicataState, dealerConfig])

    const stepData = wizardSteps[vehicleWizardStep] || null
    if (!stepData) return null

    return (
        <div className="w-full relative">
            <div className="sticky z-10 md:hidden" style={{ top: topSpace }}>
                <StepIndicator onInfoClicked={() => setSummaryVisible(true)} />
            </div>

            <div className="w-full md:flex md:justify-center">
                <div className="md:flex md:w-full md:px-8 lg:px-0 md:justify-center" style={{ maxWidth: 1024 }}>
                    <div className="hidden md:block bg-white md:w-3/5 md:my-20">
                        <div
                            className="vehicle-summary-container flex flex-col bg-color_one text-white md:rounded-lg relative md:pt-10 sticky"
                            style={{ top: topSpace > 0 ? `calc(${topSpace}px + 2rem)` : 0 }}
                        >
                            <SummaryComponent />
                        </div>
                    </div>

                    <div className="md:w-3/5 mt-5 lg:mt-10 mx-10">{stepData.component}</div>
                </div>
            </div>

            {summaryVisible && (
                <div className="vehicle-summary-container absolute inset-0 w-full h-screen z-50 bg-white text-color_one">
                    <VehicleSummary onClose={() => setSummaryVisible(false)} />
                </div>
            )}
        </div>
    )
}

export default VehicleWizard
