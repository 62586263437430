import React from 'react'

import { TAGMANAGER_TOOL_ARGS } from '../../../tagManager'

export const GenericIndicataOptionComponent = ({ option, selected, onClick, tagArgsSubmit }) => {
    const commonClasses = `flex flex-col w-full bg-color_five border text-14 rounded-lg p-2 cursor-pointer 
        border-color_five text-color_three hover:border-color_one hover:text-color_one`

    const images = option?.images?.map((image) => {
        return <img src={image.links[0].href} alt={image.key} className="object-contain" style={{ height: 78 }} />
    })

    // Display up to 3 images
    const displayedImages = images?.slice(0, 3)

    return (
        <div className="w-full">
            <div
                className={commonClasses}
                style={{ height: 110 }}
                data-tracking-event="tool-submit"
                data-tracking-info={tagArgsSubmit}
                onClick={onClick}
            >
                <div className="font-bold text-12 pb-1">{option.name}</div>
                <div
                    className="grid gap-1"
                    style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))', justifyItems: 'end' }}
                >
                    {displayedImages}
                </div>
            </div>
        </div>
    )
}

export const GenericOptionRenderer = ({
    options,
    selectedKey,
    handleClick,
    stepInfo,
    dealerConfig,
    OptionComponent,
}) => {
    const LayoutComponent = dealerConfig?.provider === 'indicata' ? IndicataLayoutWrapper : AutralisLayoutWrapper

    return (
        <LayoutComponent>
            {options.map((option) => {
                const selected = option.key === selectedKey
                const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                    event: 'submit',
                    ...stepInfo,
                    toolStepOption: option.description,
                })

                return (
                    <OptionComponent
                        key={option.key}
                        option={option}
                        selected={selected}
                        onClick={(e) => handleClick(e, option)}
                        tagArgsSubmit={tagArgsSubmit}
                    />
                )
            })}
        </LayoutComponent>
    )
}

const IndicataLayoutWrapper = ({ children }) => <div className="flex flex-wrap mb-10 flex-col gap-2">{children}</div>
const AutralisLayoutWrapper = ({ children }) => <div className="flex flex-wrap mb-10 gap-2">{children}</div>
