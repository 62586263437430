import React, { useContext, useEffect, useReducer, useState } from 'react'
import { initialState, dataReducer } from './reducer'
import {
    inspectionDamageAddHandler,
    inspectionDamageAddPicturesHandler,
    inspectionCheckValueHandler,
    inspectionFieldSetHandler,
    inspectionFieldSetHandlerIndicata,
    inspectionMultipleFieldSetHandlerIndicata,
    inspectionPictureAddHandler,
    inspectionPictureDeleteHandler,
    inspectionSetHandler,
    inspectionSetKeyHandler,
    setDealerHandler,
    inspectionDamageRemovePictureHandler,
    takeOverSetHandler,
    inspectionResetHandler,
    takeOverDocumentInitializeHandler,
    takeOverDocumentUpdateHandler,
    takeOverSetConfirmedHandler,
} from './actions'
import { getTranslatedSteps } from '../components/inspection/inspectionWizardConfig'
import { useData } from '../hooks/useData'
import { useTranslation } from 'react-i18next'
import { initSession, isAuthenticated, isAuthenticatedPending, resetSessionToDefault, updateSession } from './session'
import { getDealerFromUrl } from '../utils'
import { ClientContext, useMutation } from 'graphql-hooks'
import { forEach, includes } from 'lodash'
import i18next from 'i18next'
import { availableLanguages } from '../App'
import {
    getIndicataWizardStepsByDealerConfig,
    getWizardStepsByDealerConfig,
} from '../components/vehicle/vehicleWizardConfig'
import Cookies from 'js-cookie'
import { INDICATA_STEP_MAPPINGS } from '../components/vehicle/VehicleWizard'

const defaultContext = {
    data: initialState,
}
const DataContext = React.createContext(defaultContext)

const INSPECTION_QUERY = `query Data ($key: String!, $customer: String!) {
  inspection (key: $key, customer: $customer) {
        id
        customer
        userId        
        make
        model
        fuel
        bodyType
        transmission
        licensePlate
        mileage
        
        exteriorColor
        exteriorColorText
        exteriorQuality
        interiorColor
        interiorColorText
        interiorQuality        
        interiorUpholstery
        interiorUpholsteryText
        tyreQualityFront
        tyreQualityBack
        
        engineRange,
        kw
        
        features
        pictures {
          key         
          url
          type
        }
        
       noDamages
        damages {
         id
         location
         pictures
        }
        
        checks {
         key
         value
        }
        
        firstRegistrationYear
        firstRegistrationMonth
       
        comment
        
        currentStepId
        fullInspectionStatus
        email        
        contact {
          firstName
          lastName
          street
          number
          postalCode
          city
          telephone
          email
        }
        
        takeOver {
          state
          proposed
          proposedAmount
          proposedDate
          accepted
          acceptedAmount
          acceptedDate
          documentUrl
          expertId    
          createdDate 
          conventionUploaded
        }      
  }
}`

const TAKE_OVER_QUERY = `query Data ($key: String!, $customer: String!) {
  inspection (key: $key, customer: $customer) {          
         takeOver {
           state
           proposed
           proposedAmount
           proposedDate
           accepted
           acceptedAmount
           acceptedDate
           documentUrl
           expertId    
           createdDate 
           conventionUploaded
        }      
  }
}`

const DEALER_QUERY = `query DealerInfo($dealerKey: String!) {
 dealer(dealerKey: $dealerKey) {
    id
    key
    name
    address
    postal
    city
    telephone
    email
    facebook
    instagram
    twitter    
    website   
    logo
    defaultLanguage
    htmlHeadScript
    htmlHeadNoScript
    htmlHeadScripts
         
    dataProvider
    dealerLocator
    advancedIdentificationForm
    advancedIdentificationFormAddressRequired
    purchaseProject
    
    fontUrl
    homePage {
     html
     script
    }    
    header {
     html
     script
    }
    footer {
     html
     script
    }
    inspectionStart {
     html
     script
    }
    makes {
      key
      name
      priority
      takeOver
      purchase
    }
    transport
    account
    
    valuation
    valuationAverage
    inspection
    
    pageTitle
    favIcon
    headerImage
    copyright
    copyrightLogo
    privacyUrl
    termsAndConditionsUrl
    reloadOnLanguageChange
    socialAuthentication
    formsPrivacyTickBox
    googleTagManagerContainerId
  }
}`

const LOG_OUT_FOR_DEALER = `
    mutation Logout($dealer: String!) {
        logout(dealer: $dealer) {
           ok          
        }
    }`

const DataContextProvider = ({ children }) => {
    const dealerKeyFromUrl = getDealerFromUrl(window.location.pathname)
    const [dealerKey] = useState(dealerKeyFromUrl)
    const client = useContext(ClientContext)
    const [state, dispatch] = useReducer(dataReducer, initialState, undefined)
    const [currentInspectionStep, setCurrentInspectionStep] = useState(-1)
    const [currentVehicleDataStep, setCurrentVehicleDataStep] = useState(0)
    const [inspectionError, setInspectionError] = useState(false)
    const [inspectionLoading, setInspectionLoading] = useState(false)
    const [inspectionValuationLoading, setTakeOverLoading] = useState(false)
    const [takeOverError, setTakeOverError] = useState(false)
    const [dealerError, setDealerError] = useState(false)
    const [inspectionPending, setInspectionPending] = useState(false)
    const setField = (key, value) => inspectionFieldSetHandler(dispatch, key, value)
    const setIndicataField = (key, value) => inspectionFieldSetHandlerIndicata(dispatch, key, value)
    const setMultipleIndicataFields = (parsedState) => inspectionMultipleFieldSetHandlerIndicata(dispatch, parsedState)
    const goToVehicleDataStep = (step) => {
        // console.log('%cgoToVehicleDataStep: %s', 'color:orange', step)
        setCurrentVehicleDataStep(step)
    }

    const {
        makes,
        models,
        bodies,
        fuels,
        transmissions,
        engineRanges,
        powers,
        categories,
        regMonths,
        regYears,
        facelifts,
        seats,
        engines,
        wheelDrives,
        trims,
        bodyHeights,
        bodyLengths,
        bodyWeights,
        fetchNextState,
        resetSpecificDataFields,
  } = useData(state.inspection, state.inspectionStatus, state?.dealerConfig?.provider, setMultipleIndicataFields)
    const [validationErrorsModalData, setValidationErrorsModalData] = useState(undefined)
    // const [session, setSession] = useState(initSession(dealerKeyFromUrl));
    const [session, setSession] = useState(undefined)
    const [logoutForDealer] = useMutation(LOG_OUT_FOR_DEALER)
    const [dealerInfoLoaded, setDealerInfoLoaded] = useState(false)
    const { i18n } = useTranslation()
    const [vehicleWizardSteps, setVehicleWizardSteps] = useState(undefined)
    const [cookiesAccepted, setCookiesAccepted] = useState(false)
    const [indicataNavSteps, setIndicataNavSteps] = useState([])

    useEffect(() => {
        const cookie = Cookies.get('GDPR_ANALYTICS')
        const accepted = cookie !== undefined
        setCookiesAccepted(accepted)
    })

    useEffect(() => {
        if (!state.dealerConfig) return

        const stepsByProvider = {
            autralis: getWizardStepsByDealerConfig,
            indicata: getIndicataWizardStepsByDealerConfig,
        }

        const getSteps = stepsByProvider[state.dealerConfig.provider]
        if (getSteps) {
            setVehicleWizardSteps(getSteps(state.dealerConfig))
        }
    }, [state.dealerConfig])

    useEffect(() => {
        async function intializeSession() {
            const session = await initSession(dealerKey)
            setSession(session)
        }

        if (dealerKey) {
            intializeSession()
        }
    }, [dealerKey])

    useEffect(() => {
        if (state.dealerConfig) {
            let lng = state.dealerConfig.defaultLanguage
            if (i18n.language && i18next.language !== '') {
                const tmp = i18n.language.substring(0, 2)
                lng = includes(availableLanguages, tmp) ? tmp : lng
            }

            if (lng !== i18n.language) {
                client.setHeader('Accept-Language', lng)
                i18n.changeLanguage(lng)
            }
        }
    }, [i18n.language, state.dealerConfig])

    const fetchInspection = (key, customer) => {
        if (state.inspection.key !== undefined || inspectionLoading) {
            return
        }
        setInspectionLoading(true)
        setInspectionError(false)
        fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language,
            },
            body: JSON.stringify({
                query: INSPECTION_QUERY,
                variables: { key: key, customer: customer },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.errors !== undefined && res.errors !== null && res.errors.length > 0) {
                    //setError(``);
                    setInspectionError(true)
                } else {
                    if (res.data && res.data.inspection) {
                        const currentStep =
                            res.data.inspection.currentStepId !== null
                                ? res.data.inspection.currentStepId < 5
                                    ? res.data.inspection.currentStepId + 1
                                    : res.data.inspection.currentStepId
                                : -1
                        setCurrentInspectionStep(currentStep)

                        inspectionSetHandler(dispatch, key, res.data.inspection)
                    } else {
                        // setError(`Something went wrong.`);
                        setInspectionError(true)
                    }
                }
                setInspectionLoading(false)
            })
            .catch((error) => {
                setInspectionError(true)
                setInspectionLoading(false)
            })
    }

    const takeOverFetch = (key, customer) => {
        setTakeOverLoading(true)
        setTakeOverError(false)

        fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language,
            },
            body: JSON.stringify({
                query: TAKE_OVER_QUERY,
                variables: { key: key, customer: customer },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.errors !== undefined && res.errors !== null && res.errors.length > 0) {
                    setTakeOverError(true)
                } else {
                    if (res.data && res.data.inspection && res.data.inspection.takeOver) {
                        takeOverSetHandler(dispatch, key, res.data.inspection.takeOver)
                    } else {
                        // setError(`Something went wrong.`);
                        setTakeOverError(true)
                    }
                }
                setTakeOverLoading(false)
            })
            .catch((error) => {
                setTakeOverError(true)
                setTakeOverLoading(false)
            })
    }

    const fetchDealerInfo = (key) => {
        setDealerInfoLoaded(false)
        setDealerError(false)
        fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': i18n.language,
            },
            body: JSON.stringify({
                query: DEALER_QUERY,
                variables: { dealerKey: key },
            }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.errors !== undefined && res.errors !== null && res.errors.length > 0) {
                    setDealerError(true)
                } else {
                    if (res.data && res.data.dealer) {
                        setDealerHandler(dispatch, res.data.dealer)
                        setDealerInfoLoaded(true)
                    } else {
                        setDealerError(true)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
                setDealerError(false)
            })
    }

    const handleNextInspectionStep = () => {
        setCurrentInspectionStep(currentInspectionStep + 1)
    }

    const handlePrevInspectionStep = () => {
        setCurrentInspectionStep(currentInspectionStep - 1)
    }

    const handleNextVehicleDataStep = () => {
        setCurrentVehicleDataStep(currentVehicleDataStep + 1)
    }

    const handleVehicleWizardSetData = (data) => {
        inspectionSetHandler(dispatch, undefined, data.inspection)
        setCurrentVehicleDataStep(data.step)
    }

    const handlePrevVehicleDataStep = () => {
        // Cleanup curent step's data
        const fieldToCleanup = [
            ...vehicleWizardSteps[currentVehicleDataStep].fieldNames,
            ...vehicleWizardSteps[currentVehicleDataStep - 1].fieldNames,
        ]
        forEach(fieldToCleanup, (field) => {
            inspectionFieldSetHandler(dispatch, field, undefined)
        })

        setCurrentVehicleDataStep(currentVehicleDataStep - 1)
    }

    const saveNavStep = (step) => {
        setIndicataNavSteps((currentSteps) => [...currentSteps, step])
    }

    function handlePrevVehicleDataStepIndicata() {
        if (indicataNavSteps.length === 0) return

        const newSteps = [...indicataNavSteps]
        const poppedValue = newSteps.pop()

        setIndicataNavSteps(newSteps)

        // Dynamically determine the target step number using INDICATA_STEP_MAPPINGS
        const stepNumber = INDICATA_STEP_MAPPINGS[poppedValue.rel]?.step

        const isValidStepNumber = Number.isInteger(stepNumber) && stepNumber >= 0

        if (!isValidStepNumber) {
            console.error('Invalid stepNumber')
            return
        }

        resetInspectionStateFields(currentVehicleDataStep, stepNumber, vehicleWizardSteps, dispatch)
        resetDataFields(currentVehicleDataStep, stepNumber, vehicleWizardSteps, resetSpecificDataFields)
        goToVehicleDataStep(stepNumber)
    }

    function resetDataFields(currentVehicleDataStep, stepNumber, vehicleWizardSteps, resetSpecificDataFields) {
        const dataStepsToCleanup = []
        for (let i = currentVehicleDataStep; i >= stepNumber + 1; i--) {
            if (vehicleWizardSteps[i]?.dataFieldNames) {
                dataStepsToCleanup.push(vehicleWizardSteps[i].dataFieldNames)
            }
        }

        const dataFieldsToCleanup = [...new Set(dataStepsToCleanup.flat())]
        resetSpecificDataFields(dataFieldsToCleanup)
    }

    function resetInspectionStateFields(currentVehicleDataStep, stepNumber, vehicleWizardSteps, dispatch) {
        const stepsToCleanup = []
        for (let i = currentVehicleDataStep; i >= stepNumber; i--) {
            stepsToCleanup.push(vehicleWizardSteps[i].fieldNames)
        }

        const fieldsToCleanup = [...new Set(stepsToCleanup.flat())]

        fieldsToCleanup.forEach((field) => {
            inspectionFieldSetHandler(dispatch, field, undefined)
        })
    }

    const handleAuthenticated = (user) => {
        setSession(updateSession(user, session))
    }

    const handleSignOut = () => {
        logoutForDealer({ variables: { dealer: dealerKey } }).then((result) => {
            // if(result.data.authDeleteTokenCookie.deleted) {
            //     setSession(resetSessionToDefault())
            // }
            setSession(resetSessionToDefault())
        })
    }

    function inspectionReset() {
        inspectionResetHandler(dispatch)
        setCurrentVehicleDataStep(0)
        if (state.dealerConfig.provider === 'indicata') {
            resetSpecificDataFields([
                'makes',
                'models',
                'regmonth',
                'regdate',
                'bodies',
                'facelifts',
                'seats',
                'bodyHeights',
                'bodyLengths',
                'bodyWeights',
                'engines',
                'wheelDrives',
                'transmissions',
                'trims',
            ])
        }
    }

    return (
        <DataContext.Provider
            value={{
                dealerKey: dealerKey,
                inspection: state.inspection,
                inspectionStatus: state.inspectionStatus,
                indicataState: state.indicataState,
                indicataNavSteps,
                handlePrevVehicleDataStepIndicata,
                inspectionPending:
                    inspectionPending ||
                    (state.inspection.key !== undefined &&
                        (state.inspectionStatus === 'INITIAL' || state.inspectionStatus === 'PENDING')),
                takeOver: state.takeOver,
                takeOverError: takeOverError,
                dealer: state.dealer,
                dealerConfig: state.dealerConfig,
                inspectionWizardConfiguration: getTranslatedSteps(),
                inspectionWizardStep: currentInspectionStep,
                vehicleWizardConfiguration: vehicleWizardSteps,
                vehicleWizardStep: currentVehicleDataStep,
                inspectionError: inspectionError,
                loading: inspectionValuationLoading || inspectionLoading,
                initialDataLoaded: dealerInfoLoaded,
                initialDataError: dealerError,
                validationErrorsModalData,
                dispatch,
                session: session,
                cookiesAccepted,

                // **********
                // INSPECTION
                // **********
                fetchInspection: (key, customer) => fetchInspection(key, customer),
                setKey: (key) => inspectionSetKeyHandler(dispatch, key),
                setInspection: (key, data) => inspectionSetHandler(dispatch, key, data),
                setField: setField,
                addPicture: (key, url, type) => inspectionPictureAddHandler(dispatch, key, url, type),
                deletePicture: (key) => inspectionPictureDeleteHandler(dispatch, key),
                addDamage: (clientDamageId, location) => inspectionDamageAddHandler(dispatch, clientDamageId, location),
                addDamagePictures: (clientDamageId, pictures) =>
                    inspectionDamageAddPicturesHandler(dispatch, clientDamageId, pictures),
                removeDamagePicture: (clientDamageId, picture_url) =>
                    inspectionDamageRemovePictureHandler(dispatch, clientDamageId, picture_url),
                setCheckValue: (key, value) => inspectionCheckValueHandler(dispatch, key, value),
                setInspectionPending: (value) => setInspectionPending(value),
                inspectionReset, //vehicleDataWizardStepChange

                // **************
                // VEHICLE WIZARD
                // **************
                setData: (data) => handleVehicleWizardSetData(data),
                setNextVehicleDataStep: () => handleNextVehicleDataStep(),
                setPrevVehicleDataStep: () => handlePrevVehicleDataStep(),
                resetVehicleWizard: () => {
                    inspectionResetHandler(dispatch)
                    setCurrentVehicleDataStep(0)
                },

                // *****************
                // INSPECTION WIZARD
                // *****************
                setNextWizardStep: () => handleNextInspectionStep(),
                setPrevWizardStep: () => handlePrevInspectionStep(),

                // ******
                // DEALER
                // ******
                fetchDealer: (key) => fetchDealerInfo(key),

                // ********
                // TAKEOVER
                // ********
                takeOverFetch: (key, customer) => takeOverFetch(key, customer),
                takeOverSetHandler: (key, takeOver) => takeOverSetHandler(dispatch, key, takeOver),
                takeOverDocumentInitializeHandler: (key, name, extension) =>
                    takeOverDocumentInitializeHandler(dispatch, key, name, extension),
                takeOverDocumentUpdateHandler: (key, url) => takeOverDocumentUpdateHandler(dispatch, key, url),
                takeOverSetConfirmedHandler: (value) => takeOverSetConfirmedHandler(dispatch, value),

                // ************
                // GENERAL DATA
                // ************
                data: {
                    categories: categories, //indicata
                    regMonths: regMonths, //indicata
                    regYears: regYears, //indicata
                    facelifts: facelifts, //indicata
                    seats: seats, //indicata
                    engines: engines, //indicata
                    wheelDrives: wheelDrives, //indicata
                    trims: trims, //indicata
                    bodyHeights: bodyHeights, //indicata
                    bodyLengths: bodyLengths, //indicata
                    bodyWeights: bodyWeights, //indicata
                    makes: makes,
                    models: models,
                    bodies: bodies,
                    fuels: fuels,
                    engineRanges: engineRanges,
                    powers: powers,
                    transmissions: transmissions,
                },

                // ************
                // Session
                // ************
                isAuthenticated: () => isAuthenticated(session),
                isAuthenticatedPending: () => isAuthenticatedPending(session),
                setAuthenticated: (user) => handleAuthenticated(user),
                signOut: () => handleSignOut(),

                // ************
                // MODALS
                // ************
                setValidationErrorsModalVisible: (data) => setValidationErrorsModalData(data),

                setCookiesAccepted: (accepted) => setCookiesAccepted(accepted),

                // ************
                // INDICATA
                // ************
                fetchNextState: (url) => fetchNextState(url),
                setIndicataField: setIndicataField,
                setMultipleIndicataFields: setMultipleIndicataFields,
                goToVehicleDataStep: (step) => goToVehicleDataStep(step),
                saveNavStep: (step) => saveNavStep(step),
            }}
        >
            {children}
        </DataContext.Provider>
    )
}

export default DataContext

export { DataContextProvider }
