import i18n from '../../i18n'
import React from 'react'
import Make from './wizardsteps/Make'
import FirstRegistrationYear from './wizardsteps/FirstRegistrationYear'
import Model from './wizardsteps/Model'
import BodyType from './wizardsteps/BodyType'
import Fuel from './wizardsteps/Fuel'
import Transmission from './wizardsteps/Transmission'
import Mileage from './wizardsteps/Mileage'
import VehicleIdentification from './wizardsteps/VehicleIdentification'
import Identification from './wizardsteps/Identification'
import FirstRegistrationMonth from './wizardsteps/FirstRegistrationMonth'
import Engine from './wizardsteps/Engine'
import Purchase from './wizardsteps/Purchase'
import { filter } from 'lodash'
import { Category } from './indicata/Category'
import { Facelift } from './indicata/Facelift'
import { Seats } from './indicata/Seats'
import { Engine as IndicataEngine } from './indicata/Engine'
import { WheelDrive } from './indicata/WheelDrive'
import { Trim } from './indicata/Trim'
import { Height } from './indicata/Height'
import { Length } from './indicata/Length'
import { Weight } from './indicata/Weight'

const wizardSteps = [
    {
        title: i18n.t('Make'),
        fieldNames: ['make'],
        component: <Make />,
        required: () => true,
    },
    {
        title: i18n.t('1st registration year'),
        fieldNames: ['firstRegistrationYear'],
        component: <FirstRegistrationYear />,
        required: () => true,
    },
    {
        title: i18n.t('1st registration month'),
        fieldNames: ['firstRegistrationMonth'],
        component: <FirstRegistrationMonth />,
        required: () => true,
    },
    {
        title: i18n.t('Model'),
        fieldNames: ['model'],
        component: <Model />,
        required: () => true,
    },
    {
        title: i18n.t('Body type'),
        fieldNames: ['body'],
        component: <BodyType />,
        required: () => true,
    },
    {
        title: i18n.t('Fuel'),
        fieldNames: ['fuel'],
        component: <Fuel />,
        required: () => true,
    },
    {
        title: i18n.t('Engine'),
        fieldNames: ['engineRange'],
        component: <Engine />,
        required: () => true,
    },
    {
        title: i18n.t('Transmission'),
        fieldNames: ['transmission'],
        component: <Transmission />,
        required: () => true,
    },
    {
        title: i18n.t('Mileage'),
        fieldNames: ['mileage'],
        component: <Mileage />,
        required: () => true,
    },
    {
        title: i18n.t('License plate'),
        fieldNames: ['licensePlate', 'vin'],
        component: <VehicleIdentification />,
        required: () => true,
    },
    {
        title: i18n.t('Purchase'),
        fieldNames: ['purchase'],
        component: <Purchase />,
        required: (config) => config.purchaseProject,
    },
    {
        title: i18n.t('Identification'),
        fieldNames: ['socialVerified'],
        component: <Identification />,
        required: () => true,
    },
]

export const getWizardStepsByDealerConfig = (config) => {
    return filter(wizardSteps, (s) => s.required(config))
}

const wizardStepsIndicata = [
    {
        title: i18n.t('Category'),
        fieldNames: ['category'],
        component: <Category />,
        required: () => true,
    },
    {
        title: i18n.t('Make'),
        fieldNames: ['make'],
        dataFieldNames: ['makes'],
        component: <Make />,
        required: () => true,
    },
    {
        title: i18n.t('Model'),
        fieldNames: ['model'],
        dataFieldNames: ['models'],
        component: <Model />,
        required: () => true,
    },
    {
        title: i18n.t('1st registration month'),
        fieldNames: ['firstRegistrationMonth'],
        dataFieldNames: [],
        component: <FirstRegistrationMonth />,
        required: () => true,
    },
    {
        title: i18n.t('1st registration year'),
        fieldNames: ['firstRegistrationYear'],
        dataFieldNames: ['regYears'],
        component: <FirstRegistrationYear />,
        required: () => true,
    },
    {
        title: i18n.t('Body type'),
        fieldNames: ['body'],
        dataFieldNames: ['bodies'],
        component: <BodyType />,
        required: () => true,
    },
    {
        title: i18n.t('Facelift'),
        fieldNames: ['facelift'],
        dataFieldNames: ['facelifts'],
        component: <Facelift />,
        required: () => true,
    },
    {
        title: i18n.t('Seats'),
        fieldNames: ['seats'],
        dataFieldNames: ['seats'],
        component: <Seats />,
        required: () => true,
    },
    {
        title: i18n.t('Height'),
        fieldNames: ['bodyHeight'],
        dataFieldNames: ['bodyHeights'],
        component: <Height />,
        required: () => true,
    },
    {
        title: i18n.t('Length'),
        fieldNames: ['bodyLength'],
        dataFieldNames: ['bodyLengths'],
        component: <Length />,
        required: () => true,
    },
    {
        title: i18n.t('Weight'),
        fieldNames: ['bodyWeight'],
        dataFieldNames: ['bodyWeights'],
        component: <Weight />,
        required: () => true,
    },
    {
        title: i18n.t('Engine'),
        fieldNames: ['engine', 'engineRange', 'fuel'],
        dataFieldNames: ['engines', 'fuels'],
        component: <IndicataEngine />,
        required: () => true,
    },
    {
        title: i18n.t('Wheel drive'),
        fieldNames: ['wheelDrive'],
        dataFieldNames: ['wheelDrives'],
        component: <WheelDrive />,
        required: () => true,
    },
    {
        title: i18n.t('Transmission'),
        fieldNames: ['transmission'],
        dataFieldNames: ['transmissions'],
        component: <Transmission />,
        required: () => true,
    },
    {
        title: i18n.t('Trim'),
        fieldNames: ['trim'],
        dataFieldNames: ['trims'],
        component: <Trim />,
        required: () => true,
    },
    {
        title: i18n.t('Mileage'),
        fieldNames: ['mileage'],
        dataFieldNames: [],
        component: <Mileage />,
        required: () => true,
    },
    {
        title: i18n.t('License plate'),
        fieldNames: ['licensePlate', 'vin'],
        dataFieldNames: [],
        component: <VehicleIdentification />,
        required: () => true,
    },
    {
        title: i18n.t('Purchase'),
        fieldNames: ['purchase'],
        dataFieldNames: [],
        component: <Purchase />,
        required: (config) => config.purchaseProject,
    },
    {
        title: i18n.t('Identification'),
        fieldNames: ['socialVerified'],
        dataFieldNames: [],
        component: <Identification />,
        required: () => true,
    },
]

export const getIndicataWizardStepsByDealerConfig = (config) => {
    // console.log('wizardStepsIndicata: ', wizardStepsIndicata)
    return filter(wizardStepsIndicata, (s) => s.required(config))
}
