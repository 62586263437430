import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys, map, orderBy } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

export const Trim = () => {
    const { inspection, data, saveNavStep, fetchNextState } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo('indicata', 'equipment')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.trim) {
            errors['trim'] = t('Please select a trim')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.trim,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleTrimClick = (e, trim) => {
        let aTrack = analyticstracker()
        aTrack.trackInteraction(e)
        saveNavStep({ link: trim.link, rel: trim.rel })
        fetchNextState(trim.link)
    }

    const trimOptions = data && data.trims ? orderBy(data.trims, ['description'], ['asc']) : []

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Trim')}</div>

            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the trim of your vehicle')}:</div>

            <div className="flex flex-wrap mb-10 gap-2">
                {map(trimOptions, (trim, i) => {
                    const selected = trim.key === inspection.fuel
                    const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                        event: 'submit',
                        ...stepInfo,
                        toolStepOption: trim.description,
                    })
                    return (
                        <div key={`fuel_${trim.key}`} className="w-full">
                            <div
                                className={`flex items-center justify-center bg-color_five border text-14 rounded-lg mb-2 gap-x-2 cursor-pointer ${
                                    selected ? 'border-color_one text-color_one' : 'border-color_five text-color_three'
                                } hover:border-color_one hover:text-color_one truncate`}
                                style={{ height: 70 }}
                                data-tracking-event="tool-submit"
                                data-tracking-info={tagArgsSubmit}
                                data-tracking-commerce={tagArgsCommerce}
                                onClick={(e) => handleTrimClick(e, trim)}
                            >
                                <span>{trim.name}</span>
                                {trim.image ? <img src={trim.image} alt={trim.description} /> : null}
                            </div>
                        </div>
                    )
                })}
            </div>

            <WizardError errors={errors} />

            <WizardButtons validatorFunction={validator} />
        </div>
    )
}
