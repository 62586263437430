import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys, range } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

const FirstRegistrationMonth = () => {
    const { inspection, setField, setNextVehicleDataStep, dealerConfig, fetchNextState, data, saveNavStep } =
        useContext(DataContext)
    const [showHelp, setShowHelp] = useState(false)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo(dealerConfig.provider, 'registration')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.firstRegistrationMonth) {
            errors['firstRegistrationMonth'] = t('Please select the first registration month')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.firstRegistrationMonth,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleMonthClick = (e, month) => {
        const aTrack = analyticstracker()
        aTrack.trackInteraction(e)

        const providerHandlers = {
            indicata: () => indicataRegMonthClickHandler(month),
            autralis: () => autralisRegMonthClickHandler(month),
        }

        providerHandlers[dealerConfig.provider](month)
    }

    const indicataRegMonthClickHandler = (month) => {
        fetchNextState(month.link)
        saveNavStep({ link: month.link, rel: month.rel })
        setNextVehicleDataStep()
    }

    const autralisRegMonthClickHandler = (month) => {
        setField('firstRegistrationMonth', month.value)
        setNextVehicleDataStep()
    }

    let months = range(1, 13)
    if (dealerConfig.provider === 'indicata') {
        months = data?.regMonths
    }

    return (
        <div>
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('1st registration')}</div>

            <div className="flex">
                <div
                    className="text-16 leading-6 mb-4 mt-6"
                    dangerouslySetInnerHTML={{
                        __html: t('Select the {{styling}} month {{stylingEnd}} of first registration', {
                            styling: '<span class="font-bold">',
                            stylingEnd: '</span />',
                            interpolation: { escapeValue: false },
                        }),
                    }}
                ></div>
                <div className="flex items-center mb-4 mt-6 ml-1 relative">
                    <i
                        className="fal fa-question-circle ml-1 text-20 text-gray-700 hover:text-gray-900 cursor-pointer "
                        onMouseEnter={() => setShowHelp(true)}
                        onMouseLeave={() => setShowHelp(false)}
                        onClick={() => setShowHelp(!showHelp)}
                    />

                    {showHelp && (
                        <div
                            className="absolute right-0 top-0 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50 opa"
                            style={{ top: 25, width: 300 }}
                        >
                            <img
                                src={require('../../../assets/help-images/first-registration.jpg')}
                                alt=""
                                style={{ maxWidth: '100%' }}
                                className="rounded"
                            />
                        </div>
                    )}
                </div>
            </div>

            <MonthSelector
                months={months}
                inspection={inspection}
                handleMonthClick={handleMonthClick}
                tagArgsCommerce={tagArgsCommerce}
                dealerConfig={dealerConfig}
            />

            <WizardError errors={errors} />

            <WizardButtons validatorFunction={validator} />
        </div>
    )
}

const MonthSelector = ({ months, inspection, handleMonthClick, tagArgsCommerce }) => {
    const { dealerConfig } = useContext(DataContext)
    const stepInfo = getToolStepInfo(dealerConfig.provider, 'registration')
    return (
        <div className="flex flex-wrap mb-10">
            {months.map((month, i) => {
                const isObject = typeof month === 'object' // Check if month is an object
                const monthValue = isObject ? month.id : month // Extract value correctly
                const selected = monthValue === inspection.firstRegistrationMonth

                const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                    event: 'submit',
                    ...stepInfo,
                    toolStepOption: monthValue,
                })

                return (
                    <MonthCard
                        key={`m_${monthValue}`}
                        month={{ ...(isObject ? month : {}), value: monthValue, index: i }} // Retain all object properties
                        selected={selected}
                        handleMonthClick={handleMonthClick}
                        tagArgsSubmit={tagArgsSubmit}
                        tagArgsCommerce={tagArgsCommerce}
                    />
                )
            })}
        </div>
    )
}

const MonthCard = ({ month, selected, handleMonthClick, tagArgsSubmit, tagArgsCommerce }) => {
    return (
        <div className={`w-1/6 mb-2 ${month.index % 6 !== 5 ? 'pr-2' : ''}`}>
            <div
                className={`flex items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                    selected ? 'border-color_one text-color_one' : 'border-color_five text-color_three'
                } hover:border-color_one hover:text-color_one`}
                style={{ height: 46 }}
                onClick={(e) => handleMonthClick(e, month)}
                data-tracking-event="tool-submit"
                data-tracking-info={tagArgsSubmit}
                data-tracking-commerce={tagArgsCommerce}
            >
                {month.value}
            </div>
        </div>
    )
}

export default FirstRegistrationMonth
