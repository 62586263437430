import React, { useContext, useEffect, useState } from 'react'
import WizardButtons from '../WizardButtons'
import { keys, map, orderBy } from 'lodash'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import {
    TAGMANAGER_TOOL_ARGS,
    TAGMANAGER_COMMERCE_ARGS,
    getToolStepInfo,
} from '../../../tagManager'

export const Category = () => {
    const { inspection, data, fetchNextState, saveNavStep } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo('indicata', 'car-category')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.category) {
            errors['category'] = t('Please select a category')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.category,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleCategoryClick = (e, category) => {
        let aTrack = analyticstracker()
        aTrack.trackInteraction(e)
        saveNavStep({ link: category.link, rel: category.rel })
        fetchNextState(category.link)
    }

    const categories = orderBy(data.categories, ['category'], ['desc'])

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Category')}</div>

            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the category of your vehicle')}:</div>

            <div className="flex mb-10 gap-x-2">
                {
                    <Categories
                        categories={categories}
                        inspection={inspection}
                        tagArgsCommerce={tagArgsCommerce}
                        handleOnClick={handleCategoryClick}
                    />
                }
            </div>

            <WizardError errors={errors} />

            <WizardButtons validatorFunction={validator} />
        </div>
    )
}

const Categories = ({ categories, inspection, tagArgsCommerce, handleOnClick }) => {
    return map(categories, (category, i) => {
        const stepInfo = getToolStepInfo('indicata', 'car-category')
        const selected = category.key === inspection.make
        const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: 'submit',
            ...stepInfo,
            toolStepOption: category.name,
        })
        return (
            <div key={`category_${category.name}`} className="w-1/2">
                <div
                    className={`flex items-center text-center justify-center bg-color_five border text-14 rounded-lg cursor-pointer ${
                        selected ? 'border-color_one text-color_one' : 'border-color_five text-color_three'
                    } hover:border-color_one hover:text-color_one capitalize`}
                    style={{ height: 70 }}
                    data-tracking-event="tool-submit"
                    data-tracking-info={tagArgsSubmit}
                    data-tracking-commerce={tagArgsCommerce}
                    onClick={(e) => handleOnClick(e, category)}
                >
                    {category.name}
                </div>
            </div>
        )
    })
}
