import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

export const Engine = () => {
    const { inspection, data, fetchNextState, saveNavStep } = useContext(DataContext)
    const [showHelp, setShowHelp] = useState(false)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo('indicata', 'engine')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.engine) {
            errors['engine'] = t('Please select engine')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.engine,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleEngineClick = (e, engine) => {
        let aTrack = analyticstracker()
        aTrack.trackInteraction(e)
        saveNavStep({ link: engine.link, rel: engine.rel })
        fetchNextState(engine.link)
    }

    const engines = data && data.engines ? data.engines : []

    const handleShowHelp = () => {
        if (!showHelp) {
            setShowHelp(true)
        }
    }

    const handleHideHelp = () => {
        setTimeout(() => {
            setShowHelp(false)
        }, 250)
    }

    const EngineHeader = () => {
        return (
            <>
                <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Chose your engine')}</div>
                <div className="flex">
                    <div className="text-16 leading-6 mb-4 mt-6">{t('Select the engine of your vehicle')}:</div>
                    <div className="flex items-center mb-4 mt-6 ml-1 relative">
                        <div
                            onMouseEnter={handleShowHelp}
                            onMouseLeave={handleHideHelp}
                            onClick={() => setShowHelp(!showHelp)}
                        >
                            <i className="fal fa-question-circle ml-1 text-20 text-gray-700 hover:text-gray-900 cursor-pointer " />

                            {showHelp && (
                                <div
                                    className="absolute right-0 top-0 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50"
                                    style={{ top: 25, width: 300 }}
                                >
                                    <img
                                        src={require('../../../assets/help-images/engineCC.jpg')}
                                        alt=""
                                        style={{ maxWidth: '100%' }}
                                        className="rounded"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <EngineHeader />
            <EngineList groupedEngines={groupByFuelType(engines)} handleEngineClick={handleEngineClick} />
            <WizardError errors={errors} />
            <WizardButtons validatorFunction={validator} />
        </div>
    )
}

const groupByFuelType = (engines) => {
    const grouped = {}
    engines.forEach((engine) => {
        const fuelType = engine?.description?.find((d) => d.label === 'fuel_type').value
        if (!grouped[fuelType]) {
            grouped[fuelType] = []
        }
        grouped[fuelType].push(engine)
    })
    return grouped
}

const findEngineSpecValue = (engine, key, unit) => {
    const item = engine?.description?.find((d) => d.label === key)
    return item?.value != null ? item.value + unit : ''
}

const EngineList = ({ groupedEngines, handleEngineClick }) => {
    const stepInfo = getToolStepInfo('indicata', 'engine')
    return (
        <div className="flex flex-wrap mb-10">
            {Object.entries(groupedEngines).map(([fuelType, engines], i) => (
                <div key={fuelType} className="mb-6 w-full">
                    <h2 className="text-lg font-semibold mb-2">{fuelType}:</h2>
                    <div className="grid grid-cols-2 gap-2">
                        {engines.map((engine, j) => {
                            const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                                event: 'submit',
                                ...stepInfo,
                                toolStepOption: engine.description,
                            })
                            const engineName = engine.name
                            const enginePowerKw = findEngineSpecValue(engine, 'power[kW]', 'kW')
                            const enginePowerPS = findEngineSpecValue(engine, 'power[PS]', 'PK')
                            const engineCC = findEngineSpecValue(engine, 'displacement[cc]', 'ccm')
                            const engineKey = `${fuelType}_${engineName}_${enginePowerKw}_${j}`.replace(/\s+/g, '_')

                            return (
                                <div
                                    key={engineKey}
                                    className="flex items-center justify-center bg-color_five border text-14 rounded-lg
                                    mb-2 cursor-pointer border-color_five text-color_three
                                  hover:border-color_one hover:text-color_one truncate"
                                    style={{ height: 70 }}
                                    data-tracking-event="tool-submit"
                                    data-tracking-info={tagArgsSubmit}
                                    onClick={(e) => handleEngineClick(e, engine)}
                                >
                                    <div className="p-2">
                                        <h3 className="font-semibold">{engineName}</h3>
                                        <p className="text-sm text-gray-600">
                                            {enginePowerKw} {enginePowerPS}
                                        </p>
                                        <p className="text-sm text-gray-600">{engineCC}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            ))}
        </div>
    )
}
