import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys, map, orderBy } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'

export const WheelDrive = () => {
    const { inspection, data, fetchNextState } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo('indicata', 'wheelbase')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.wheelDrive) {
            errors['wheelDrive'] = t('Please select a wheel drive')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.wheelDrive,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleTransmissionClick = (e, wheelDrive) => {
        let aTrack = analyticstracker()
        aTrack.trackInteraction(e)
        fetchNextState(wheelDrive.link)
    }

    const wheelDrive = data && data.wheelDrives ? orderBy(data.wheelDrives, ['description'], ['asc']) : []

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Wheel Drive')}</div>

            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the wheel drive of your vehicle')}:</div>

            <div className="flex mb-10 gap-2">
                {map(wheelDrive, (wheelDrive, i) => {
                    const selected = wheelDrive.key === inspection.wheelDrive
                    const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                        event: 'submit',
                        ...stepInfo,
                        toolStepOption: wheelDrive.description,
                    })
                    return (
                        <div key={`transmission_${wheelDrive.key}`} className="w-1/2 mb-2">
                            <div
                                className={`flex items-center flex-1 justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                                    selected ? 'border-color_one text-color_one' : 'border-color_five text-color_three'
                                } hover:border-color_one hover:text-color_one`}
                                data-tracking-event="tool-submit"
                                data-tracking-info={tagArgsSubmit}
                                data-tracking-commerce={tagArgsCommerce}
                                style={{ height: 70 }}
                                onClick={(e) => handleTransmissionClick(e, wheelDrive)}
                            >
                                {wheelDrive.description}
                            </div>
                        </div>
                    )
                })}
            </div>

            <WizardError errors={errors} />

            <WizardButtons validatorFunction={validator} />
        </div>
    )
}
