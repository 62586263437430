import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientContext } from 'graphql-hooks'
import DataContext from '../data/context'

const LanguagePicker = () => {
    const { i18n } = useTranslation()
    const client = useContext(ClientContext)
    const { dealerConfig } = useContext(DataContext)

    const changeLanguage = (lng) => {
        client.setHeader('Accept-Language', lng)
        i18n.changeLanguage(lng)

        if (dealerConfig.reloadOnLanguageChange || dealerConfig.provider === 'indicata') {
            const urlParams = new URLSearchParams(window.location.search)
            if (urlParams.has('language')) {
                urlParams.delete('language')
            }

            const hasParams = urlParams.toString() !== ''
            if (hasParams) {
                window.location = `${window.location.origin}${window.location.pathname}?${urlParams.toString()}`
            } else {
                window.location = `${window.location.origin}${window.location.pathname}`
            }
        }
    }

    return (
        <div className="flex text-color_three text-14 font-bold">
            {/*<div className={`cursor-pointer uppercase ${(currentLanguage === 'en') ? 'text-color_one' : 'text-color_three'}`} onClick={() => i18n.changeLanguage('en')}> EN</div>*/}
            {/*<span className="ml-1 mr-1">/</span>*/}
            <div
                className={`cursor-pointer uppercase ${
                    i18n.language && i18n.language.startsWith('nl') ? 'text-color_one' : 'text-color_three'
                }`}
                onClick={() => changeLanguage('nl')}
            >
                {' '}
                NL
            </div>
            <span className="ml-1 mr-1">/</span>
            <div
                className={`cursor-pointer uppercase ${
                    i18n.language && i18n.language.startsWith('fr') ? 'text-color_one' : 'text-color_three'
                }`}
                onClick={() => changeLanguage('fr')}
            >
                {' '}
                FR
            </div>
        </div>
    )
}

export default LanguagePicker
