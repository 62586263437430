import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys, map, orderBy } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'
import { GenericOptionRenderer, GenericIndicataOptionComponent } from './GenericOptionRenderer'

export const Height = () => {
    const { inspection, data, fetchNextState, saveNavStep } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo('indicata', 'height')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.bodyHeight) {
            errors['height'] = t('Please select a height')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.height,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleHeightClick = (e, height) => {
        let aTrack = analyticstracker()
        aTrack.trackInteraction(e)
        saveNavStep({ link: height.link, rel: height.rel })
        fetchNextState(height.link)
    }

    const heightOptions = data && data.bodyHeights ? orderBy(data.bodyHeights, ['description'], ['asc']) : []

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Height')}</div>
            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the height of your vehicle')}:</div>
            <GenericOptionRenderer
                options={heightOptions}
                selectedKey={inspection.bodyHeight}
                handleClick={handleHeightClick}
                stepInfo={stepInfo}
                dealerConfig={{ provider: 'indicata' }}
                OptionComponent={GenericIndicataOptionComponent}
            />
            <WizardError errors={errors} />
            <WizardButtons validatorFunction={validator} />
        </div>
    )
}
