import { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
    makeMapper,
    modelMapper,
    categoryMapper,
    monthMapper,
    dateMapper,
    bodiesMapper,
    faceliftsMapper,
    heightLengthWeightMapper,
    seatsMapper,
    enginesMapper,
    wheelDrivesMapper,
    transmissionsMapper,
    trimsMapper,
} from '../components/vehicle/indicata/mappers'

const stateQuery = `query State {
    entryPoint
  }`

const nextStateQuery = `query NextState($url: String!) {
    nextState(url: $url)
  }`

const makesQuery = `query Makes {
  makes{
     id
     key
     name       
     priority 
     disabled
  }
}`

const modelsQuery = `query Models($make: String!, $age: Int!) {
  models(make: $make, age: $age) {
     id
     key
     name    
     disabled    
  }
}`

const bodiesQuery = `query Bodies($model: String!) {
  bodyTypes(model: $model){
     id
     key
     icon
     description
  }
}`

const fuelsQuery = `query Fuels($model: String!) {
  fuels(model: $model){
     id
     key
     description
  }
}`

const transmissionsQuery = `query Transmissions($model: String!) {
  transmissions(model: $model){
     id
     key
     description
  }
}`

const engineRangesQuery = `query EngineRanges($model: String!, $fuel: String!) {
  engineRanges(model: $model, fuel: $fuel){
     id
     key
     description
     kw
     priority
  }
}`

const powerRangesQuery = `query powerRanges($model: String!) {
    powerRanges(model: $model){
       id
       key
       description
       kw
       priority
    }
  }`

export function useData(inspection, status, provider, setMultipleIndicataFields) {
    const { i18n } = useTranslation()
    const [loading] = useState(true)
    const [error] = useState(null)
    const [makes, setMakes] = useState([])
    const [categories, setCategories] = useState([])
    const [regMonths, setRegMonths] = useState([])
    const [regYears, setRegYears] = useState([])
    const [facelifts, setFacelifts] = useState([])
    const [wheelDrives, setWheelDrives] = useState([])
    const [trims, setTrims] = useState([])
    const [seats, setSeats] = useState([])
    const [engines, setEngines] = useState([])
    const [models, setModels] = useState([])
    const [bodies, setBodies] = useState([])
    const [fuels, setFuels] = useState([])
    const [engineRanges, setEngineRanges] = useState([])
    const [powers, setPowers] = useState([])
    const [transmissions, setTransmissions] = useState([])
    const [bodyHeights, setBodyHeights] = useState([])
    const [bodyLengths, setBodyLengths] = useState([])
    const [bodyWeights, setBodyWeights] = useState([])

    const dataFieldsMappings = {
        makes: {
            setter: setMakes,
        },
        models: {
            setter: setModels,
        },
        regmonth: {
            setter: setRegMonths,
        },
        regdate: { setter: setRegYears },
        bodies: {
            setter: setBodies,
        },
        facelifts: {
            setter: setFacelifts,
        },
        seats: {
            setter: setSeats,
        },
        bodyHeights: {
            setter: setBodyHeights,
        },
        bodyLengths: {
            setter: setBodyLengths,
        },
        bodyWeights: {
            setter: setBodyWeights,
        },
        engines: {
            setter: setEngines,
        },
        wheelDrives: {
            setter: setWheelDrives,
        },
        transmissions: {
            setter: setTransmissions,
        },
        trims: {
            setter: setTrims,
        },
    }

    const resetSpecificDataFields = (fieldsToReset) => {
        // console.log("%c💥 resetSpecificDataFields: %s", "color:LightCoral", fieldsToReset)
        fieldsToReset.forEach((field) => {
            const fieldConfig = dataFieldsMappings[field]
            if (fieldConfig?.setter) {
                fieldConfig.setter([])
            }
        })
    }

    useEffect(() => {
        if (provider === 'autralis') {
            fetchMakes()
        } else if (provider === 'indicata') {
            fetchEntrypoint()
        }
    }, [status, inspection.key, provider])

    useEffect(() => {
        if (inspection.make && inspection.firstRegistrationMonth && provider === 'autralis') {
            setModels([])
            const age = moment().diff(
                moment([inspection.firstRegistrationYear, inspection.firstRegistrationMonth - 1, 1]),
                'months',
            )
            fetchModels(inspection.make, age)
        }
    }, [inspection.make, inspection.firstRegistrationMonth, provider])

    useEffect(() => {
        if (provider === 'autralis') {
            if (inspection.model) {
                setBodies([])
                setFuels([])
                setTransmissions([])
                fetchBodies(inspection.model)
                fetchFuels(inspection.model)
                console.log('pre fecth power')
                fetchPowers(inspection.model)
                fetchTransmissions(inspection.model)
            }

            if (inspection.model && inspection.fuel) {
                fetchEnginesRanges(inspection.model, inspection.fuel)
            }
        }
    }, [inspection.model, inspection.fuel, i18n.language, provider])

    const fetchMakes = () => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: makesQuery }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.makes) {
                    setMakes(res.data.makes)
                }
            })
            .catch((error) => {})
    }

    const fetchModels = (make, age) => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: modelsQuery, variables: { make: make, age: age } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.models) {
                    setModels(res.data.models)
                }
            })
            .catch((error) => {})
    }

    const fetchBodies = (model) => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: bodiesQuery, variables: { model: model } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.bodyTypes) {
                    setBodies(res.data.bodyTypes)
                }
            })
            .catch((error) => {})
    }

    const fetchFuels = (model) => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: fuelsQuery, variables: { model: model } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.fuels) {
                    setFuels(res.data.fuels)
                }
            })
            .catch((error) => {})
    }

    const fetchTransmissions = (model) => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: transmissionsQuery, variables: { model: model } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.transmissions) {
                    setTransmissions(res.data.transmissions)
                }
            })
            .catch((error) => {})
    }

    const fetchEnginesRanges = (model, fuel) => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: engineRangesQuery, variables: { model: model, fuel: fuel } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.engineRanges) {
                    setEngineRanges(res.data.engineRanges)
                }
            })
            .catch((error) => {})
    }

    const fetchPowers = (model, fuel) => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: powerRangesQuery, variables: { model: model } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data.powerRanges) {
                    setPowers(res.data.powerRanges)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const fetchEntrypoint = () => {
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: stateQuery }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data) {
                    // console.log('fetchEntrypoint data: ', res.data)
                    setCategories(categoryMapper(res.data))
                }
            })
            .catch((error) => {})
    }

    const fetchNextState = (url) => {
        console.log('trigger fetchNextState, url: ', url)
        fetch('/graphql', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept-Language': i18n.language },
            body: JSON.stringify({ query: nextStateQuery, variables: { url: url } }),
        })
            .then((res) => res.json())
            .then((res) => {
                if (res.data) {
                    // console.log('fetchNextState data: ', res.data)
                    responseProcessor(res.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const responseProcessor = (response) => {
        const parsedState = JSON.parse(response.nextState)

        // Keys correspond to indicata `rel` values
        const indicataStepMappings = {
            make: {
                setter: setMakes,
                mapper: makeMapper,
            },
            model: {
                setter: setModels,
                mapper: modelMapper,
            },
            regmonth: {
                setter: setRegMonths,
                mapper: monthMapper,
            },
            regdate: { setter: setRegYears, mapper: dateMapper },
            body: {
                setter: setBodies,
                mapper: bodiesMapper,
            },
            facelift: {
                setter: setFacelifts,
                mapper: faceliftsMapper,
            },
            seats: {
                setter: setSeats,
                mapper: seatsMapper,
            },
            bodyHeight: {
                setter: setBodyHeights,
                mapper: heightLengthWeightMapper,
            },
            bodyLength: {
                setter: setBodyLengths,
                mapper: heightLengthWeightMapper,
            },
            weight: {
                setter: setBodyWeights,
                mapper: heightLengthWeightMapper,
            },
            engine: {
                setter: setEngines,
                mapper: enginesMapper,
            },
            wheeldrive: {
                setter: setWheelDrives,
                mapper: wheelDrivesMapper,
            },
            transmission: {
                setter: setTransmissions,
                mapper: transmissionsMapper,
            },
            trim: {
                setter: setTrims,
                mapper: trimsMapper,
            },
        }

        // Sets the values for the current wizard step
        // nextStep:[] means that most likely the valuation link is ready
        if (parsedState.nextStep || parsedState.nextStep.length !== 0) {
            const stepElement = parsedState?.nextStep[0]
            if (stepElement && indicataStepMappings[stepElement.rel]) {
                const { setter, mapper } = indicataStepMappings[stepElement.rel]
                setter(mapper(response))
            }
        }

        // Auto completes the passed steps
        setMultipleIndicataFields(parsedState)
    }

    return {
        makes,
        models,
        bodies,
        fuels,
        engineRanges: engineRanges,
        powers,
        transmissions,
        loading: loading,
        error,
        categories,
        regMonths,
        regYears,
        facelifts,
        seats,
        engines,
        wheelDrives,
        trims,
        bodyHeights,
        bodyLengths,
        bodyWeights,
        fetchNextState,
        resetSpecificDataFields,
    }
}
