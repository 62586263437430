import { actionTypes } from "./reducer";
import { mapAutoValues } from "../components/vehicle/indicata/mappers";

export const inspectionSetKeyHandler = (dispatch, key) => {
  dispatch({
    type: actionTypes.INSPECTION_SET_KEY,
    payload: {
      key,
    },
  });
};

export const inspectionSetHandler = (dispatch, key, data) => {
  dispatch({
    type: actionTypes.INSPECTION_SET,
    payload: {
      key: key,
      ...data,
    },
  });
};

export const inspectionPictureAddHandler = (dispatch, key, url, type) => {
  dispatch({
    type: actionTypes.INSPECTION_ADD_PICTURE,
    payload: {
      key,
      url,
      type,
    },
  });
};

export const inspectionPictureDeleteHandler = (dispatch, key) => {
  dispatch({
    type: actionTypes.INSPECTION_DELETE_PICTURE,
    payload: {
      key,
    },
  });
};

export const inspectionDamageAddHandler = (
  dispatch,
  clientDamageId,
  location
) => {
  dispatch({
    type: actionTypes.INSPECTION_ADD_DAMAGE,
    payload: {
      clientDamageId,
      location,
    },
  });
};

export const inspectionDamageAddPicturesHandler = (
  dispatch,
  clientDamageId,
  pictureUrls
) => {
  dispatch({
    type: actionTypes.INSPECTION_DAMAGE_ADD_PICTURES,
    payload: {
      clientDamageId,
      pictureUrls,
    },
  });
};

export const inspectionDamageRemovePictureHandler = (
  dispatch,
  clientDamageId,
  picture_url
) => {
  dispatch({
    type: actionTypes.INSPECTION_DAMAGE_REMOVE_PICTURE,
    payload: {
      clientDamageId,
      picture_url,
    },
  });
};

export const inspectionCheckValueHandler = (dispatch, key, value) => {
  dispatch({
    type: actionTypes.INSPECTION_SET_CHECK_VALUE,
    payload: {
      key,
      value,
    },
  });
};

export const inspectionFieldSetHandler = (dispatch, field, value) => {
  dispatch({
    type: actionTypes.INSPECTION_SET_FIELD,
    payload: {
      field,
      value,
    },
  });
};

export const inspectionFieldSetHandlerIndicata = (dispatch, field, value) => {
    dispatch({
        type: actionTypes.INSPECTION_SET_FIELD_INDICATA,
        payload: {
            field,
            value,
        },
    })
}

export const inspectionMultipleFieldSetHandlerIndicata = (dispatch, parsedState) => {
  const fields = mapAutoValues(parsedState);
  dispatch({
    type: actionTypes.SET_INDICATA_FIELDS_AND_STATE,
    payload: { fields, parsedState }
  });
};

export const setDealerHandler = (dispatch, dealer) => {
  dispatch({
    type: actionTypes.SET_DEALER,
    payload: {
      dealer,
    },
  });
};

export const inspectionResetHandler = (dispatch) => {
  dispatch({
    type: actionTypes.INSPECTION_RESET,
    payload: {},
  });
};

export const takeOverSetHandler = (dispatch, key, data) => {
  dispatch({
    type: actionTypes.TAKE_OVER_SET,
    payload: {
      key: key,
      ...data,
    },
  });
};

export const takeOverDocumentInitializeHandler = (
  dispatch,
  key,
  name,
  extension
) => {
  dispatch({
    type: actionTypes.TAKE_OVER_DOCUMENT_INITIALIZE,
    payload: {
      key,
      name,
      extension,
    },
  });
};

export const takeOverDocumentUpdateHandler = (dispatch, key, url) => {
  dispatch({
    type: actionTypes.TAKE_OVER_DOCUMENT_UPDATE,
    payload: {
      key,
      url,
    },
  });
};

export const takeOverSetConfirmedHandler = (dispatch, value) => {
  dispatch({
    type: actionTypes.TAKE_OVER_SET,
    payload: {
      value,
    },
  });
};
