export const makeMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary.toLowerCase(),
            name: item.summary,
            link: item.href,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const categoryMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.entryPoint)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.summary,
            link: item.href,
            rel: item.rel,
            category: getQueryParamFromUrl(item.href, 'category') || '',
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

const getQueryParamFromUrl = (url, param) => {
    const urlParams = new URLSearchParams(new URL(url).search)
    return urlParams.get(param)
}

export const modelMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.summary,
            link: item.href,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const monthMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            id: item.summary,
            link: item.href,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const dateMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            id: item.summary,
            link: item.href,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const bodiesMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.name,
            icon: '',
            description: item.summary,
            name: item.summary,
            link: item.href,
            images: item?.images ?? undefined,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const faceliftsMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.summary,
            description: item.name,
            link: item.href,
            images: item?.images ?? undefined,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const heightLengthWeightMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.summary,
            description: item.name,
            link: item.href,
            images: item?.images ?? undefined,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const seatsMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.summary,
            link: item.href,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const enginesMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.name,
            link: item.href,
            description: item.description,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const wheelDrivesMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.name,
            link: item.href,
            description: item.summary,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}
export const transmissionsMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.name,
            link: item.href,
            // description: item.description
            description: item.summary,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const trimsMapper = (input) => {
    try {
        const parsedState = JSON.parse(input.nextState)

        if (!parsedState.nextStep || !Array.isArray(parsedState.nextStep)) {
            throw new Error('Invalid input format')
        }

        return parsedState.nextStep.map((item) => ({
            key: item.summary,
            name: item.name,
            link: item.href,
            rel: item.rel,
        }))
    } catch (error) {
        console.error('Error parsing input:', error.message)
        return []
    }
}

export const mapIndicataEngineValues = (data) => {
    if (!data || typeof data !== 'object') {
        return { kw: '', fuel: '', engineRange: '', powerRange: '' }
    }

    const engineSummary = data.summary || ''

    const getValue = (label) => {
        const entry = data.description?.find((item) => item.label === label)
        return entry ? entry.value : ''
    }

    return {
        engine: engineSummary,
        kw: getValue('power[kW]'),
        fuel: getValue('fuel_type'),
        engineRange: getValue('displacement[cc]'),
        powerRange: '',
    }
}

export const mapDateValues = (regDate) => {
    if (!regDate) return { regMonth: '', regYear: '' }

    const match = regDate.summary.match(/\d{4}-\d{2}/)

    if (!match) return { regMonth: '', regYear: '' }

    // Extract year and date from "yyyy-mm" value
    const [year, month] = match[0].split('-')

    return {
        regMonth: month,
        regYear: year,
    }
}

export const mapAutoValues = (data) => {
    const { regMonth, regYear } = data?.regDate ? mapDateValues(data.regDate) : { regMonth: '', regYear: '' }
    const { engine, kw, fuel, engineRange, powerRange } = data?.engine
        ? mapIndicataEngineValues(data.engine)
        : { engine: '', kw: '', fuel: '', engineRange: '', powerRange: '' }

    const category = data?.category ? { key: data.category?.key, name: data.category?.name } : []

    const bodyHeight = data?.bodyHeight
    const bodyLength = data?.bodyLength
    const bodyWeight = data?.weight

    return {
        category,
        make: data?.make?.summary ?? '',
        model: data?.model?.summary ?? '',
        firstRegistrationMonth: regMonth,
        firstRegistrationYear: regYear,
        body: data?.body?.summary ?? '',
        facelift: data?.facelift ?? '',
        seats: data?.seats?.name ?? '',
        engine,
        kw,
        fuel,
        engineRange,
        powerRange,
        wheelDrive: data?.wheelDrive?.summary ?? '',
        transmission: data?.transmission?.summary ?? '',
        trim: data?.trim ?? '',
        variant: data?.valuation?.[0]?.variants?.[0] ?? '',
        indicataValuation: data?.valuation?.[0] ?? '',
        bodyHeight,
        bodyWeight,
        bodyLength,
    }
}
