import React, { useContext, useEffect, useState } from 'react'
import DataContext from '../../../data/context'
import { useTranslation } from 'react-i18next'
import { keys, orderBy } from 'lodash'
import WizardButtons from '../WizardButtons'
import WizardError from '../WizardError'
import analyticstracker from 'analyticstracker'
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS, getToolStepInfo } from '../../../tagManager'
import { GenericOptionRenderer, GenericIndicataOptionComponent } from '../indicata/GenericOptionRenderer'

const BodyType = () => {
    const { inspection, data, setField, setNextVehicleDataStep, dealerConfig, fetchNextState, saveNavStep } = useContext(DataContext)
    const [errors, setErrors] = useState({})
    const { t } = useTranslation()
    const stepInfo = getToolStepInfo(dealerConfig.provider, 'body')

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection])

    useEffect(() => {
        let aTrack = analyticstracker()
        aTrack.trackImpression('tool-start')
    }, [])

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        ...stepInfo,
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {}

        if (!inspection.body) {
            errors['body'] = t('Please select a body type')
            let aTrack = analyticstracker()
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: 'error',
                ...stepInfo,
                errorType: 'user-error',
                errorMessage: errors.body,
            })
            let event = {
                event: 'tool-error',
                info: JSON.parse(errorTrackingInfo),
                commerce: JSON.parse(tagArgsCommerce),
            }
            aTrack.trackEvent(event)
        }

        setErrors(errors)

        return keys(errors).length <= 0
    }

    const validator = () => {
        return validate()
    }

    const handleBodyClick = (e, body) => {
        const aTrack = analyticstracker()
        aTrack.trackInteraction(e)

        const providerHandlers = {
            indicata: () => indicataBodyClickHandler(body),
            autralis: () => autralisBodyClickHandler(body),
        }

        providerHandlers[dealerConfig.provider](body)
    }

    const indicataBodyClickHandler = (body) => {
        saveNavStep({ link: body.link, rel: body.rel })
        fetchNextState(body.link)
    }

    const autralisBodyClickHandler = (body) => {
        setField('body', body.key)
        setNextVehicleDataStep()
    }

    const bodyOptions = data && data.bodies ? orderBy(data.bodies, ['priority', 'name'], ['desc', 'asc']) : []

    return (
        <div
            data-tracking-event="tool-start"
            data-tracking-info={tagArgsStart}
            data-tracking-commerce={tagArgsCommerce}
        >
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Body type')}</div>
            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the body type of your vehicle')}:</div>
            <GenericOptionRenderer
                options={bodyOptions}
                selectedKey={inspection.body}
                handleClick={handleBodyClick}
                stepInfo={stepInfo}
                dealerConfig={dealerConfig}
                OptionComponent={
                    dealerConfig.provider === 'indicata' ? GenericIndicataOptionComponent : AutralisBodyOption
                }
            />
            <WizardError errors={errors} />
            <WizardButtons validatorFunction={validator} />
        </div>
    )
}

const AutralisBodyOption = ({ option, selected, onClick, tagArgsSubmit }) => (
    <div className="w-1/3 mb-2">
        <div
            className={`flex flex-col items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer 
                ${selected ? 'border-color_one text-color_one' : 'border-color_five text-color_three'}
                hover:border-color_one hover:text-color_one`}
            style={{ height: 70 }}
            data-tracking-event="tool-submit"
            data-tracking-info={tagArgsSubmit}
            onClick={onClick}
        >
            <i className={`${option.icon} ${selected ? 'text-color_one' : ''}`} style={{ fontSize: 25 }} />
            <span className="mt-2 font-bold text-12">{option.description}</span>
        </div>
    </div>
)

export default BodyType
